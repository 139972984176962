import { default as adminasuserGzijq1pieyMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/adminasuser.vue?macro=true";
import { default as indexjU56J6KjWBMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/index.vue?macro=true";
import { default as mes_45chargeshKkUYAen1mMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-charges.vue?macro=true";
import { default as mes_45consommationsw3UjNCAB1qMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-consommations.vue?macro=true";
import { default as mes_45documentsL86RItrN4gMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-documents.vue?macro=true";
import { default as mes_45equipementsDv3l3vr584Meta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-equipements.vue?macro=true";
import { default as mon_45profilR97EcscWVxMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mon-profil.vue?macro=true";
import { default as mon_45tableau_45de_45bordcpbKUzPQuNMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mon-tableau-de-bord.vue?macro=true";
import { default as motdepasse_45oublieLVJQbApt9ZMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/motdepasse-oublie.vue?macro=true";
import { default as renouveler_45motdepassepup3aF8PgbMeta } from "/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/renouveler-motdepasse.vue?macro=true";
export default [
  {
    name: "adminasuser",
    path: "/adminasuser",
    meta: adminasuserGzijq1pieyMeta || {},
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/adminasuser.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexjU56J6KjWBMeta || {},
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mes-charges",
    path: "/mes-charges",
    meta: mes_45chargeshKkUYAen1mMeta || {},
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-charges.vue").then(m => m.default || m)
  },
  {
    name: "mes-consommations",
    path: "/mes-consommations",
    meta: mes_45consommationsw3UjNCAB1qMeta || {},
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-consommations.vue").then(m => m.default || m)
  },
  {
    name: "mes-documents",
    path: "/mes-documents",
    meta: mes_45documentsL86RItrN4gMeta || {},
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-documents.vue").then(m => m.default || m)
  },
  {
    name: "mes-equipements",
    path: "/mes-equipements",
    meta: mes_45equipementsDv3l3vr584Meta || {},
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mes-equipements.vue").then(m => m.default || m)
  },
  {
    name: "mon-profil",
    path: "/mon-profil",
    meta: mon_45profilR97EcscWVxMeta || {},
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mon-profil.vue").then(m => m.default || m)
  },
  {
    name: "mon-tableau-de-bord",
    path: "/mon-tableau-de-bord",
    meta: mon_45tableau_45de_45bordcpbKUzPQuNMeta || {},
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/mon-tableau-de-bord.vue").then(m => m.default || m)
  },
  {
    name: "motdepasse-oublie",
    path: "/motdepasse-oublie",
    meta: motdepasse_45oublieLVJQbApt9ZMeta || {},
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/motdepasse-oublie.vue").then(m => m.default || m)
  },
  {
    name: "renouveler-motdepasse",
    path: "/renouveler-motdepasse",
    meta: renouveler_45motdepassepup3aF8PgbMeta || {},
    component: () => import("/home/bas/app_1c3881d6-c875-4181-859f-235aa8983641/pages/renouveler-motdepasse.vue").then(m => m.default || m)
  }
]